import React from "react";
import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";



import Section1 from "../components/section-1";

import CenteredTextComponent from "../components/centered-text";
import TwoColumnsRowComponent from "../components/two-columns-row";
import TextImageRowComponent from "../components/text-image-row";

import problemIntroImage from "../images/problem/problem-intro-d.jpg";
import section1PouredImage from "../images/problem/s1-poured-d.jpg";
import section1BottledImage from "../images/problem/s1-bottled-d.jpg";
import section2ConfusingImage from "../images/problem/s2-2-confusing-d.jpg";
import section2NothingImage from "../images/problem/s2-3-nothing-d.jpg";
import section2ViciousImage from "../images/problem/s2-4-vicious-d.jpg";
import section2EverythingImage from "../images/problem/s2-1-everything-d.jpg";
import section3TruckImage from "../images/problem/s3-1-truck-d.jpg";
import section3BiodegradablesImage from "../images/problem/s3-2-biodegradables-d.jpg";
import section3InfrastructureImage from "../images/problem/s3-3-infrastructure-d.jpg";
import section4HardcostsImageD from "../images/problem/s4-1-hardcosts-d.jpg";
import section4BuildingsImageD from "../images/problem/s4-2-buildings-d.jpg";
import section4TaxesImageD from "../images/problem/s4-3-taxes-d.jpg";
import section5CarryingImage from "../images/problem/s5-1-carrying-d.jpg";
import section5CleaningImage from "../images/problem/s5-2-cleaning-d.jpg";
import section5LosingImage from "../images/problem/s5-3-losing-d.jpg";

import s3IntroD from "../images/cup/s3-intro-d.jpg";


const ProblemPage = () => (
  <Layout currentPage="problem">
    <UnoSection
      backgroundImage={problemIntroImage}
      minHeight="70vh"
      maxWidth="600px"
      bottomPadding
    >
      <div className="thispagetitle">The Problem</div>
      <CenteredTextComponent
        title="The world produces 700 billion single-use cups and bottles a year."
        text="It’s obvious that our disposable culture is harmful to the planet. But most of us don’t appreciate the depths and complexities of the wasteful reality we’ve created.To truly understand the impact of how the world currently drinks, we have to look at the entire life cycle a beverage goes through. Everything from the moment materials are first extracted until the empty container ends up in a landfill or the ocean."
      />
    </UnoSection>

    <UnoSection maxWidth="900px" bottomPadding backgroundColor="#eeedea">
      <h1>Wasteful before it’s even wasted.</h1>
      <p>
        By the time a beverage gets to you, it’s already been causing harm to
        the environment. There are thousands of different beverages, each with
        its own unique characteristics, requirements and shelf-life. Generally,
        though, we can separate them into two types of categories:
      </p>
      <Section1
        image={section1PouredImage}
        title="Prepared and poured at the point of use"
        text="The cups, lids, straws and sleeves have to be manufactured using natural resources. It’s not only the wood that makes paper and the petroleum that makes plastic—the process of extracting these raw materials and turning them into a beverage container is polluting. The fuel, coal, water and other resources that power cranes, trucks and factories all leave their mark on our environment."
      />
      <Section1
        image={section1BottledImage}
        title="Bottled at a bottling plant"
        text="This is even more wasteful because in addition to the bottles, cartons and cans, the beverages themselves (liquid) now have to be transported long distances—sometimes from another continent—before they get to you. They have to be stocked and refrigerated, requiring additional space and energy. Often, they move between multiple means of transportation and facilities until they reach their final destination. "
      />
    </UnoSection>

    <UnoSection maxWidth="820px" bottomPadding backgroundColor="#ffffff">
      <CenteredTextComponent
        title="Recycling is good. It's just not good enough."
        text="The truth is that only a fraction of single-use bottles and cups are ever recycled. And while recycling is better than not recycling, it’s far from being an ideal solution. "
      />
      <TwoColumnsRowComponent
        leftImage={section2EverythingImage}
        leftTitle="Not everything can be recycled"
        leftText="Here are just a couple of examples: coffee cups have a special plastic lining to prevent leaks which makes them too expensive to break apart and recycle; the size and shape of plastic straws make them nearly impossible to be recycled."
        rightImage={section2ConfusingImage}
        rightTitle="Terribly confusing"
        rightText="People have a hard time understanding recycling and what can actually be recycled. Where does a biodegradable coffee cup go? What about the plastic lid? When it comes to the actual moment of recycling, even those of us with the best intentions don't always know what to do. "
      />

      <TwoColumnsRowComponent
        leftImage={section2NothingImage}
        leftTitle="Nothing's free"
        leftText="The immense energy and natural resources that go into recycling are taxing on our environment. It’s a long process that involves collecting, transporting, sorting, cleaning and processing of the disposables, which is polluting our air and water."
        rightImage={section2ViciousImage}
        rightTitle="Vicious cycle"
        rightText="At the end of the recycling process, we’re left with raw materials. More energy and resources are then required to produce yet another disposable, single-use container. And so the cycle continues."
      />
    </UnoSection>

    <UnoSection
      maxWidth="760px"
      bottomPadding
      backgroundColor="#000"
      color="#fff"
    >
      <CenteredTextComponent
        title="Biodegradables are not a real solution."
        text="When you look at the big picture, these feel-good options aren't all that great. It’s true that biodegradable and compostable single-use containers are made from materials that return to nature more easily than plastic. But remember, what happens to the cup or bottle after it’s been used is only a part of the problem."
      />

      <TextImageRowComponent
        image={section3TruckImage}
        imagePosition="right"
        title="The full life-cycle"
        text="Pre-bottled drinks that come in a biodegradable container still have a significant footprint in terms of sourcing, transportation and waste disposal. A truck delivering 10,000 biodegradable bottles of mineral water is just as polluting as a truck with 10,000 plastic bottles of mineral water."
      />

      <TextImageRowComponent
        image={section3BiodegradablesImage}
        imagePosition="left"
        title="Everything is made from something"
        text="Often, the materials biodgerables are made of require a good amount of natural resources to grow and extract. The crops have to be planted, watered, harvested and processed before they can become a beverage container."
      />

      <TextImageRowComponent
        image={section3InfrastructureImage}
        imagePosition="right"
        title="Insufficient infrastructure"
        text="Buildings and cities aren’t properly equipped to handle biodegradables at scale. And because of confusion, biodegradables often end in the wrong place, creating inefficiencies in the traditional recycling process."
      />
    </UnoSection>

    <UnoSection backgroundColor="#eeedea" bottomPadding maxWidth="780px">
      <CenteredTextComponent
        title="We're all paying for it."
        text="Disposables are expensive. We pay for it every time we get a beverage, and it’s more than you think. On top of direct costs we experience as consumers or business owners, the financial burden extends far beyond the moment of purchase."
      />

      <TextImageRowComponent
        centered={true}
        image={section4HardcostsImageD}
        imagePosition="left"
        title="Hard cost"
        text="The price of a single-use container ranges from 10¢ to as much as 30¢, depending on the size, materials, printing, etc. Now think about the time it takes staff at coffee shops and offices to deal with disposable cups, the amount of space they take and the waste collection costs."
      />

      <TextImageRowComponent
        centered={true}
        image={section4BuildingsImageD}
        imagePosition="left"
        title="Buildings and offices"
        text="In a growing number of cities, buildings get fined for not complying with recycling regulations. This can add up to thousands of dollars per month. As a result, management companies and landlords have to increase monthly rent and maintenance fees."
      />

      <TextImageRowComponent
        centered={true}
        image={section4TaxesImageD}
        imagePosition="left"
        title="Taxes"
        text="Eventually waste has to be collected by a municipality. More trash means more work which means more money. Our taxes that could otherwise be invested in infrastructure, education, healthcare and community development are wasted on waste."
      />
    </UnoSection>

    <UnoSection
      maxWidth="760px"
      bottomPadding="100px"
      backgroundColor="#fff"
      color="#000"
    >
      <CenteredTextComponent
        title="It’s hard to do the right thing. Really hard."
        text="There’s no real end-to-end solution to help us consume a variety of beverages sustainably. Reusables have never been properly implemented in our distribution system, which makes them less convenient to use. So we revert back to the single-use plastic and paper cups. Yes, they are destroying the planet, but it’s the best we’ve got."
      />

      <TextImageRowComponent
        image={section5CarryingImage}
        imagePosition="right"
        title="Carrying"
        text="There’s no way to conveniently carry multiple reusable drinking containers, each suitable for a different type of beverage. What do you do with a coffee mug after you’re done drinking your coffee? Many people are willing to carry one reusable, but aren’t willing to carry three. "
      />

      <TextImageRowComponent
        image={section5CleaningImage}
        imagePosition="left"
        title="Cleaning"
        text="Cleaning reusable cups, mugs and straws requires special tools and a great deal of time. It’s absolutely impossible to do as a customer on the go. Because it’s a demanding task, reusables quickly become unpleasant to drink from. It’s one of the main reasons people abandon them at some point. "
      />

      <TextImageRowComponent
        image={section5LosingImage}
        imagePosition="right"
        title="Losing"
        text="How many times have you lost your reusable bottle or cup? Losing them seems like it may be even more wasteful than just using conventional disposables. After all, these well-made reusables require more materials and energy to manufacture, not to mention deep pockets to replace."
      />
    </UnoSection>

    <UnoSection
      verticalCenter
      maxWidth="750px"
      minHeight="100vh"
      backgroundImage={s3IntroD}
    >
      <CenteredTextComponent
        title="We can do better than that."
        text="Uno is the most comprehensive solution for eliminating single-use beverage containers. Our vision is simple: we should all have one incredible cup of our own that can be easily filled with all the beverages we love. No more paper cups, plastic bottles or straws. We’re partnering with the most forward-thinking workplaces and merchants to enable Uno anywhere you get beverages."
      
      />
          <MediaQuery key="desktop" query="(min-width: 768px)">
            <Link to="/reserve"
              className="standard-cta">
              Reserve Your Uno 
            </Link>
          </MediaQuery>

          <MediaQuery key="mobile" query="(max-width: 767px)">
            <Link
              to="/reserve-mobile"
              className="standard-cta">
              Reserve Your Uno
            </Link>
            </MediaQuery>

    </UnoSection>


  </Layout>
);

export default ProblemPage;
