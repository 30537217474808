import React from "react";
import InGrid from "./in-grid";

const ImageTitleText = ({ image, title, text }) => (
  <>
    <InGrid>
      <img src={image} alt="" className="contained" />
    </InGrid>
    <InGrid columns={6}>
      <p className="aligned-left">
        <span className="bold-text">{title}</span>
        <br />
        {text}
      </p>
    </InGrid>
  </>
);

export default ImageTitleText;
