import React from "react";
import styled from "styled-components";

const TwoColumnsRowStyledComponent = styled.div`
  @media (min-width: 768px) {
    margin-top: 80px;
  }

  img {
    display: block;
    max-width: 100%;

    @media (max-width: 767px) {
      margin-top: 80px;
    }
  }
`;

const TwoColumnsRowComponent = ({
  leftImage,
  leftTitle,
  leftText,
  rightImage,
  rightTitle,
  rightText,
}) => (
  <TwoColumnsRowStyledComponent className="container">
    <div className="row justify-content-md-between">
      <div className="col-md-5">
        <img src={leftImage} alt="" />
        <p className="aligned-left">
          <span className="bold-text">{leftTitle}</span>
          <br />
          {leftText}
        </p>
      </div>
      <div className="col-md-5">
        <img src={rightImage} alt="" />
        <p className="aligned-left">
          <span className="bold-text">{rightTitle}</span>
          <br />
          {rightText}
        </p>
      </div>
    </div>
  </TwoColumnsRowStyledComponent>
);

export default TwoColumnsRowComponent;
