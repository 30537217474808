import React from "react";
import styled from "styled-components";

const TextImageRowStyledComponent = styled.div`
  margin-top: 80px;

  p {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    .order-md-last > * {
      float: right;
    }
  }

  img {
    display: block;
    max-width: 100%;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
`;

const TextImageRowComponent = ({
  image,
  title,
  text,
  imagePosition = "left",
  centered = false,
}) => {
  let firstColumnClass = centered ? "col-md-7" : "col-md-6";
  firstColumnClass += ` order-md-${
    imagePosition === "left" ? "last" : "first"
  }`;

  let secondColumnsClass = centered ? "col-md-3 offset-md-1" : "col-md-6";
  secondColumnsClass += ` order-md-${
    imagePosition === "left" ? "first" : "last"
  }`;

  return (
    <TextImageRowStyledComponent
      imagePosition={imagePosition}
      className="container"
    >
      <div className="row align-items-center">
        <div className={firstColumnClass}>
          <p className="aligned-left">
            <span className="bold-text">{title}</span>
            <br />
            {text}
          </p>
        </div>
        <div className={secondColumnsClass}>
          <img src={image} alt="" />
        </div>
      </div>
    </TextImageRowStyledComponent>
  );
};

export default TextImageRowComponent;
